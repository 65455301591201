import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Cookies from 'js-cookie';
import axios from 'axios';
import moment from 'moment';

// # MATERIAL UI
import { Chip, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// * ICONS
import FaceIcon from '@material-ui/icons/Face';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EcoIcon from '@material-ui/icons/Eco';
import BusinessIcon from '@material-ui/icons/Business';
import AddIcon from '@material-ui/icons/Add';

// # CUSTOM COMPONENTS
import LastOrders from '../../../components/User/LastOrders';
import UserInfo from '../../../components/User/UserInfo';
import CreateDiscount from '../../../components/User/CreateDiscount';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    margin: '0 5px 0 5px',
  },
}));

export default function UserProfil() {
  const { id } = useParams();
  const classes = useStyles();
  const token = Cookies.get('jwt');

  const [state, setState] = useState({
    user: [],
    orders: [],
    orderDisplay: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const user = await axios.get(`${process.env.REACT_APP_API}/users/${id}`);
      setState((prev) => ({
        ...prev,
        user: user.data.data.user,
      }));
      const orders = await axios.get(`${process.env.REACT_APP_API}/orders/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedData = orders.data.data.order.sort((a, b) =>
        moment(b.createdAt).diff(a.createdAt)
      );
      setState((prev) => ({
        ...prev,
        orders: sortedData,
        orderDisplay: sortedData,
      }));
    };
    fetchData();
  }, [id, token]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.flex}>
        <div>
          <h1 style={{ margin: '0' }}>
            {state.user.firstname} {state.user.lastname}{' '}
            <Chip
              label={
                state.user.role === 'superAdmin'
                  ? 'Super administrateur'
                  : state.user.role === 'admin'
                  ? 'Administrateur'
                  : state.user.role === 'eco'
                  ? 'Eco-acteur'
                  : state.user.role === 'user'
                  ? 'Utilisateur simple'
                  : 'Conciergerie'
              }
              color={
                state.user.role === 'admin' || state.user.role === 'superAdmin'
                  ? 'primary'
                  : 'default'
              }
              size="small"
              icon={
                state.user.role === 'superAdmin' || state.user.role === 'admin' ? (
                  <VerifiedUserIcon />
                ) : state.user.role === 'eco' ? (
                  <EcoIcon />
                ) : state.user.role === 'user' ? (
                  <FaceIcon />
                ) : (
                  <BusinessIcon />
                )
              }
            />
          </h1>
        </div>
        <Button
          type="button"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          href={`/admin/users/createOrder/${id}`}
        >
          Créer une commande
        </Button>
      </Grid>
      <Grid item xs={8}>
        <UserInfo user={state.user} orders={state.orders} />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateDiscount id={id} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LastOrders user={state.user} order={state.orders} orderDisplay={state.orderDisplay} />
      </Grid>
    </Grid>
  );
}
