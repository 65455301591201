import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import 'moment/locale/fr';

import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Link,
  Button,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import Cookies from 'js-cookie';

import axios from 'axios';
import TableMenu from '../../../components/User/TableMenu';

const useStyles = makeStyles((theme) => ({
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  table: {
    minWidth: 650,
  },
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const columns = [
  { id: 'user', label: 'Utilisateur', minWidth: 100, align: 'left' },
  { id: 'email', label: 'Email', minWidth: 100, align: 'left' },
  { id: 'city', label: 'Ville', minWidth: 100, align: 'left' },
  { id: 'zipCode', label: 'CP', minWidth: 100, align: 'left' },
  {
    id: 'createdAt',
    label: "Date d'inscription",
    minWidth: 100,
    align: 'left',
  },
  { id: 'role', label: 'Rôle', minWidth: 100, align: 'left' },
  { id: 'actions', label: 'Actions', minWidth: 100, align: 'right' },
];

export default function UserList() {
  const classes = useStyles();
  const token = Cookies.get('jwt');
  const { query } = useParams();

  const [state, setState] = useState({
    data: [],
    dataDisplay: [],
    sortedBy: '-createdAt',
    maxLen: 0,
  });

  // # TABLE
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    if (newPage >= 0) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleSearch = async (e) => {
    e.persist();
    const { value } = e.target;

    if (value.length >= 3) {
      await axios
        .post(
          `${process.env.REACT_APP_API}/users/search`,
          { search: e.target.value },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const userData = res.data.data;
          setState((prev) => ({
            ...prev,
            data: userData,
            dataDisplay: userData,
            maxLen: res.data.maxLen,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (value.length === 0) {
      let role = '';
      query !== 'all' && (role = `?role=${query}`);
      const buffer = query !== 'all' ? '&' : '?';

      await axios
        .get(
          `${process.env.REACT_APP_API}/users/${role}${buffer}page=${
            page + 1
          }&limit=${rowsPerPage}&sort=${state.sortedBy}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const userData = res.data.data.users;
          setState((prev) => ({
            ...prev,
            data: userData,
            dataDisplay: userData,
            maxLen: res.data.maxLen,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let role = '';
      query !== 'all' && (role = `?role=${query}`);
      const buffer = query !== 'all' ? '&' : '?';

      await axios
        .get(
          `${process.env.REACT_APP_API}/users/${role}${buffer}page=${
            page + 1
          }&limit=${rowsPerPage}&sort=${state.sortedBy}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const userData = res.data.data.users;
          setState((prev) => ({
            ...prev,
            data: userData,
            dataDisplay: userData,
            maxLen: res.data.maxLen,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [query, page, rowsPerPage, state.sortedBy, token]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.top}>
            <h2>Tous les utilisateurs</h2>
            <div className={classes.search}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                href="/admin/newUser"
                startIcon={<AddIcon />}
              >
                Nouveau
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h3>Filtre</h3>
            <TextField
              placeholder="Chercher un utilisateur..."
              inputProps={{ 'aria-label': 'Chercher un utilisateur' }}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.dataDisplay.map((user, idx) => (
                <TableRow key={idx}>
                  <TableCell align="left">
                    <Link href={`/admin/users/${user._id}`}>
                      {user.firstname} {user.lastname}
                    </Link>
                  </TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">
                    {user.address.length > 0 && user.address[0].city}
                  </TableCell>
                  <TableCell align="left">
                    {user.address.length > 0 && user.address[0].zipCode}
                  </TableCell>
                  <TableCell align="left">
                    {user.createdAt ? moment(user.createdAt).format('LLL') : ''}
                  </TableCell>
                  <TableCell align="left">{user.role}</TableCell>
                  <TableCell align="right">
                    <TableMenu id={user._id} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={state.maxLen}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
