import React, { useState, useEffect, Fragment } from 'react';

// # NOTISTACK
import { useSnackbar } from 'notistack';

// # LODASH
import cloneDeep from 'lodash/cloneDeep';

import Script from 'react-load-script';

// # MATERIAL UI
import {
  IconButton,
  Button,
  Paper,
  TextField,
  Chip,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  FormControlLabel,
  Switch,
  Link,
  Modal,
  Backdrop,
  Fade,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

// # METERIAL UI ICONS
import { Mail, Label, Check, Edit, Loyalty } from '@material-ui/icons';

import Cookies from 'js-cookie';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  badges: {
    '&> div': {
      marginRight: '1em',
    },
  },
  headerFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: '1rem',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    outline: 'none',
    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',

      '& > div': {
        marginBottom: '1rem',
      },
    },
  },
}));

const columns = [
  { id: 'update', label: '', minWidth: 50, align: 'left' },
  { id: 'zipCode', label: 'Code Postal', minWidth: 50, align: 'left' },
  { id: 'city', label: 'Ville', minWidth: 50, align: 'left' },
  { id: 'street', label: 'Adresse', minWidth: 50, align: 'left' },
  { id: 'comment', label: 'Commentaire', minWidth: 50, align: 'left' },
  { id: 'isMain', label: 'Adr. Livr', minWidth: 50, align: 'right' },
  { id: 'isBill', label: 'Adr. Factu', minWidth: 50, align: 'right' },
];

function Row(props) {
  return (
    <TableRow>
      <TableCell>
        <IconButton onClick={() => props.handleEditAddress(props.idx)}>
          <Edit />
        </IconButton>
      </TableCell>
      <TableCell>{props.address.zipCode}</TableCell>
      <TableCell>{props.address.city}</TableCell>
      <TableCell>{props.address.street}</TableCell>
      <TableCell>{props.address.comment}</TableCell>
      <TableCell align="right">{props.address.isMain && <Check />}</TableCell>
      <TableCell align="right">{props.address.isBill && <Check />}</TableCell>
    </TableRow>
  );
}

function UserInfos(props) {
  return (
    <div>
      <div className={props.badges}>
        <Chip
          icon={<Mail />}
          label="Newsletter"
          color={props.user.newsLetter ? 'primary' : 'default'}
        />
        <Chip
          icon={<Label />}
          label="Étiquettes groupées"
          color={props.user.groupLabels ? 'primary' : 'default'}
        />
        {props.user.role === 'eco' && props.user.ecoPoints ? (
          <Chip
            icon={<Loyalty />}
            label={
              props.user.ecoPoints && props.user.ecoPoints > 0
                ? `${props.user.ecoPoints} Eco points`
                : 'Aucun Eco point'
            }
            color={props.user.ecoPoints && props.user.ecoPoints > 0 ? 'primary' : 'default'}
          />
        ) : null}
      </div>
      <p>
        <b>Prénom :</b> {props.user.firstname}
      </p>
      <p>
        <b>Nom :</b> {props.user.lastname}
      </p>
      <p>
        <b>Téléphone :</b> {props.user.phoneNumber}
      </p>
      <p>
        <b>Email :</b> <Link href={`mailto:${props.user.email}`}>{props.user.email}</Link>
      </p>
      <p>
        <b>Complément de profil :</b> {props.user.information}
      </p>
    </div>
  );
}

function UserAddress(props) {
  return (
    <TableContainer className={props.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.user.address &&
            props.user.address.map((el, idx) => (
              <Row key={idx} address={el} idx={idx} handleEditAddress={props.handleEditAddress} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function UserInfo(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dialogNewEco, setDialogNewEco] = useState(false);

  const [state, setState] = useState({
    idfCities: [],
    userId: '',
    orders: [],
    user: {
      role: '',
      ecoPoints: 0,
      newsLetter: null,
      groupLabels: null,
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      information: '',
      address: [],
    },
    isEditingInfos: false,
    isEditingAddress: false,
    updatedAddress: {
      idx: '',
      addressId: '',
      street: '',
      city: '',
      zipCode: '',
      comment: '',
      isMain: false,
      isBill: false,
    },
    editingUser: {},
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleRemove = async (id) => {
    const token = Cookies.get('jwt');

    if (state.orders.length > 0) {
      enqueueSnackbar('Impossible de supprimer cette utilisateur car il possède des commandes', {
        variant: 'error',
      });
    } else {
      axios
        .delete(`${process.env.REACT_APP_API}/users/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((window.location = '/admin/users/list/all'));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const idfCities = await axios.get(`${process.env.REACT_APP_API}/idfCities`).catch((err) => {
        console.log(err.response);
      });

      const idfCitiesFormat = idfCities.data.data.cities.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });

      setState((prev) => ({
        ...prev,
        idfCities: [...idfCitiesFormat],
      }));
    };

    fetchData();

    const { user, orders } = props;
    setState((prev) => ({
      ...prev,
      userId: user._id,
      orders,
      user: {
        role: user.role,
        ecoPoints: user.ecoPoints || 0,
        newsLetter: user.newsLetter,
        groupLabels: user.groupLabels,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phoneNumber: user.phoneNumber,
        information: user.information,
        address: user.address,
      },
      editingUser: {
        role: user.role,
        ecoPoints: user.ecoPoints || 0,
        newsLetter: user.newsLetter,
        groupLabels: user.groupLabels,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phoneNumber: user.phoneNumber,
        information: user.information,
        address: user.address,
      },
    }));
  }, [props]);

  const handleUpdate = (event) => {
    event.preventDefault();
    const token = Cookies.get('jwt');

    axios
      .patch(`${process.env.REACT_APP_API}/users/${state.userId}`, state.editingUser, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        enqueueSnackbar('Les modifications ont bien été prises en compte', {
          variant: 'success',
        });

        setState((prev) => ({
          ...prev,
          user: state.editingUser,
          isEditingInfos: false,
        }));
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  const handleSaveAddress = () => {
    const token = Cookies.get('jwt');

    const { updatedAddress } = state;

    let formatAddress;
    if (updatedAddress.addressId !== '') {
      formatAddress = {
        _id: updatedAddress.addressId,
        city: updatedAddress.city,
        comment: updatedAddress.comment,
        isBill: updatedAddress.isBill,
        isMain: updatedAddress.isMain,
        street: updatedAddress.street,
        title: updatedAddress.title,
        zipCode: updatedAddress.zipCode,
      };
    } else {
      formatAddress = {
        city: updatedAddress.city,
        comment: updatedAddress.comment,
        isBill: updatedAddress.isBill,
        isMain: updatedAddress.isMain,
        street: updatedAddress.street,
        title: updatedAddress.title,
        zipCode: updatedAddress.zipCode,
      };
    }

    const addressWoFormat = cloneDeep(state.user.address).filter(
      (el, idx) => idx !== updatedAddress.idx * 1
    );

    // INFOS : CHECK IF UPDATEDADDRESS ISMAIN & ISBILL -> ONLY ONE ADDRESS WITH ISMAIN / ISBILL
    if (formatAddress.isMain && formatAddress.isBill) {
      addressWoFormat.forEach((address) => {
        address.isMain = false;
        address.isBill = false;
      });
    } else {
      if (formatAddress.isMain) {
        addressWoFormat.forEach((address) => {
          address.isMain = false;
        });
      } else {
        const hadIsMain = addressWoFormat.find((address) => address.isMain);
        if (!hadIsMain) {
          return enqueueSnackbar('Le profil doit contenir au moins une adresse de livraison', {
            variant: 'error',
          });
        }
      }

      if (formatAddress.isBill) {
        addressWoFormat.forEach((address) => {
          address.isBill = false;
        });
      } else {
        const hadIsBill = addressWoFormat.find((address) => address.isBill);
        if (!hadIsBill) {
          return enqueueSnackbar('Le profil doit contenir au moins une adresse de facturation', {
            variant: 'error',
          });
        }
      }
    }

    const newAddress = [formatAddress, ...addressWoFormat];

    axios
      .patch(
        `${process.env.REACT_APP_API}/users/address/${state.userId}`,
        { address: newAddress },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setState((prev) => ({
          ...prev,
          isEditingAddress: false,
          user: {
            ...prev.user,
            address: newAddress,
          },
        }));
      })
      .catch((err) => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
      });
  };

  const handleEditing = (type) => {
    switch (type) {
      case 'infos':
        setState((prev) => ({
          ...prev,
          editingUser: prev.user,
          isEditingInfos: !prev.isEditingInfos,
        }));
        break;
      case 'address':
        setState((prev) => ({
          ...prev,
          isEditingAddress: !prev.isEditingAddress,
        }));
        break;
      default:
        console.log('Mauvais type');
    }
  };

  const handleEditAddress = (idx) => {
    setState((prev) => ({
      ...prev,
      isEditingAddress: true,
      updatedAddress: {
        idx,
        addressId: prev.user.address[idx]._id,
        title: prev.user.address[idx].title,
        street: prev.user.address[idx].street,
        city: prev.user.address[idx].city,
        zipCode: prev.user.address[idx].zipCode,
        comment: prev.user.address[idx].comment,
        isMain: prev.user.address[idx].isMain,
        isBill: prev.user.address[idx].isBill,
      },
    }));
  };

  const handleNewAddress = () => {
    setState((prev) => ({
      ...prev,
      isEditingAddress: true,
      updatedAddress: {
        ...prev.updatedAddress,
        idx: `${prev.user.address.length + 1}`,
      },
    }));
  };

  const handleCloseModal = () => {
    setState((prev) => ({
      ...prev,
      isEditingAddress: false,
      updatedAddress: {
        title: '',
        street: '',
        city: '',
        zipCode: '',
        comment: '',
        isMain: false,
        isBill: false,
      },
    }));
  };

  const handleScriptLoad = () => {
    const options = {
      types: ['address'],
      componentRestrictions: {
        country: ['fr'],
      },
      fields: ['address_components'],
    };

    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const components = place.address_components;
      let address;
      let zipCode;
      let name;

      console.log(components);

      components.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'locality':
            name = component.long_name;
            break;
          case 'street_number':
            address = component.long_name;
            break;
          case 'route':
            if (address) {
              address += ` ${component.long_name}`;
            } else {
              address = component.long_name;
            }
            break;
          case 'postal_code':
            zipCode = component.long_name;
            break;
          default:
            break;
        }
      });

      console.log(name, address, zipCode);

      setState((prev) => ({
        ...prev,
        updatedAddress: {
          ...prev.updatedAddress,
          street: address,
          city: name,
          zipCode,
        },
      }));
    });
  };

  const handleUpdateAddress = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      updatedAddress: {
        ...prev.updatedAddress,
        [event.target.id]: event.target.value,
      },
    }));
  };

  const handleUpdateCheck = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      updatedAddress: {
        ...prev.updatedAddress,
        [event.target.id]: event.target.checked,
      },
    }));
  };

  const handleSwitch = (type) => {
    switch (type) {
      case 'newsLetter':
        setState((prev) => ({
          ...prev,
          editingUser: {
            ...prev.editingUser,
            newsLetter: !prev.editingUser.newsLetter,
          },
        }));
        break;
      case 'labels':
        setState((prev) => ({
          ...prev,
          editingUser: {
            ...prev.editingUser,
            groupLabels: !prev.editingUser.groupLabels,
          },
        }));
        break;
      default:
        console.log('Mauvais type');
    }
  };

  const handleChangeEditing = (event) => {
    event.persist();
    const { id, value } = event.target;

    if (id === 'ecoPoints') {
      return setState((prev) => ({
        ...prev,
        editingUser: {
          ...prev.editingUser,
          [id]: value * 1,
        },
      }));
    }

    setState((prev) => ({
      ...prev,
      editingUser: {
        ...prev.editingUser,
        [id]: value,
      },
    }));

    if (id === 'role' && value === 'eco') setDialogNewEco(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialogEco = () => {
    setDialogNewEco(false);
  };

  const handleSendNewEco = async () => {
    const token = Cookies.get('jwt');

    await axios
      .post(
        `${process.env.REACT_APP_API}/users/newEco`,
        { id: props.user._id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDialogNewEco(false);

        enqueueSnackbar('Mail envoyé', {
          variant: 'success',
        });
      })
      .catch((err) => {
        const { errors } = err.response.data.error;
        for (const error in errors) {
          enqueueSnackbar(`${errors[error].message}`, {
            variant: 'error',
          });
        }
      });
  };

  return (
    <>
      {state.isEditingAddress && (
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_G_PLACES_KEY}&libraries=places`}
          onLoad={handleScriptLoad}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={state.isEditingAddress}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={state.isEditingAddress}>
          <div className={classes.paperModal}>
            <h2 id="transition-modal-title">Modifier l'adresse</h2>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  label="Titre"
                  type="text"
                  variant="outlined"
                  value={state.updatedAddress.title}
                  onChange={handleUpdateAddress}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Adresse"
                  type="text"
                  variant="outlined"
                  id="autocomplete"
                  fullWidth
                  defaultValue={
                    state.updatedAddress.street !== ''
                      ? `${state.updatedAddress.street} ${state.updatedAddress.city} ${state.updatedAddress.zipCode}`
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="comment"
                  label="Commentaire"
                  type="text"
                  variant="outlined"
                  value={state.updatedAddress.comment}
                  onChange={handleUpdateAddress}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.updatedAddress.isMain}
                      id="isMain"
                      onChange={handleUpdateCheck}
                    />
                  }
                  label="Livraison"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.updatedAddress.isBill}
                      id="isBill"
                      onChange={handleUpdateCheck}
                    />
                  }
                  label="Facturation"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSaveAddress()}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Paper style={{ marginBottom: '1.4rem' }} className={classes.paper}>
        <div className={classes.headerFlex}>
          <h2>Informations</h2>
          <div>
            {state.isEditingInfos ? (
              <>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleClickOpen}
                  style={{ marginRight: '1rem' }}
                >
                  Supprimer
                </Button>
                <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title">
                  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Confirmez la suppression
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Êtes-vous sûre de vouloir supprimer le compte
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                      Annuler
                    </Button>
                    <Button onClick={() => handleRemove(state.userId)} color="primary">
                      Confirmer
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : null}
            <Button
              type="button"
              variant="contained"
              color={state.isEditingInfos ? 'secondary' : 'primary'}
              onClick={() => handleEditing('infos')}
            >
              {state.isEditingInfos ? 'Annuler' : 'Éditer'}
            </Button>
          </div>
        </div>
        {state.isEditingInfos ? (
          <form className={classes.form} onSubmit={handleUpdate}>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.editingUser.newsLetter}
                    name="newsLetter"
                    color={state.editingUser.newsLetter ? 'primary' : 'secondary'}
                    onChange={() => handleSwitch('newsLetter')}
                  />
                }
                label="Newsletter"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.editingUser.groupLabels}
                    name="labels"
                    color={state.editingUser.groupLabels ? 'primary' : 'secondary'}
                    onChange={() => handleSwitch('labels')}
                  />
                }
                label="Étiquettes groupées"
              />
            </div>
            <div>
              <FormControl variant="outlined" className={classes.marginB}>
                <InputLabel>Rôle</InputLabel>
                <Select
                  id="role"
                  native
                  value={state.editingUser.role}
                  onChange={handleChangeEditing}
                  label="Rôle"
                >
                  <option value="user">Utilisateur</option>
                  <option value="conciergerie">Conciergerie</option>
                  <option value="entreprise">Entreprise</option>
                  <option value="eco">Eco acteur</option>
                  <option value="admin">Administrateur</option>
                  <option value="superAdmin">Super administrateur</option>
                </Select>
              </FormControl>
              <Dialog
                open={dialogNewEco}
                onClose={handleCloseDialogEco}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  Nouvel utilisateur Eco acteur
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Voulez-vous prévenir l'utilisateur par mail ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseDialogEco} color="secondary">
                    Ne pas envoyer
                  </Button>
                  <Button color="primary" onClick={handleSendNewEco}>
                    Envoyer
                  </Button>
                </DialogActions>
              </Dialog>
              <TextField
                id="ecoPoints"
                label="Eco Points"
                type="number"
                variant="outlined"
                value={state.editingUser.ecoPoints}
                onChange={handleChangeEditing}
              />
            </div>
            <div>
              <TextField
                id="firstname"
                label="Prénom"
                type="text"
                variant="outlined"
                value={state.editingUser.firstname}
                onChange={handleChangeEditing}
              />
              <TextField
                id="lastname"
                label="Nom"
                type="text"
                variant="outlined"
                value={state.editingUser.lastname}
                onChange={handleChangeEditing}
              />
            </div>
            <TextField
              id="phoneNumber"
              label="Numéro de téléphone"
              type="text"
              variant="outlined"
              value={state.editingUser.phoneNumber}
              onChange={handleChangeEditing}
            />
            <TextField
              id="email"
              label="Email"
              type="text"
              variant="outlined"
              value={state.editingUser.email}
              onChange={handleChangeEditing}
            />
            <TextField
              id="information"
              label="Complément de profil"
              type="text"
              variant="outlined"
              value={state.editingUser.information}
              onChange={handleChangeEditing}
            />
            <Button variant="contained" color="primary" type="submit">
              Valider
            </Button>
          </form>
        ) : (
          <UserInfos badges={classes.badges} user={state.user} />
        )}
      </Paper>

      <Paper className={classes.paper}>
        <div className={classes.headerFlex}>
          <h2>Adresses</h2>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => handleNewAddress()}
          >
            Ajouter une adresse
          </Button>
        </div>
        <UserAddress
          container={classes.container}
          user={state.user}
          handleEditAddress={handleEditAddress}
        />
      </Paper>
    </>
  );
}
