import axios from 'axios';

const reactAppApi = process.env.REACT_APP_API;

// * SETUP HEADERS OBJ FOR REQUESTS
const retrieveHeaders = (token) =>
  token
    ? {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    : {
        headers: {
          'Content-Type': 'application/json',
        },
      };

// * AXIOS GET REQUEST
export const axiosGetRequest = async (path, token) => {
  console.log(path, token);
  const headersConfig = retrieveHeaders(token);
  if (!token) {
    return axios.get(`${reactAppApi}${path}`);
  }
  return axios.get(`${reactAppApi}${path}`, headersConfig);
};

// * AXIOS POST REQUEST
export const axiosPostRequest = async (path, token, data) => {
  const headersConfig = retrieveHeaders(token);
  if (!token) return axios.post(`${reactAppApi}${path}`, data);
  return axios.post(`${reactAppApi}${path}`, data, headersConfig);
};

// * AXIOS PATCH REQUEST
export const axiosPatchRequest = async (path, token, data) => {
  const headersConfig = retrieveHeaders(token);
  return axios.patch(`${reactAppApi}${path}`, data, headersConfig);
};

// * AXIOS DELETE REQUEST
export const axiosDeleteRequest = async (path, token) => {
  const headersConfig = retrieveHeaders(token);
  return axios.delete(`${reactAppApi}${path}`, headersConfig);
};
