// # UTILS
import currencyFormater from './currencyFormater';
import formatNum from './numberFormater';

// INFOS : GET AN ORDER AS PARAM
// INFOS : RETURN TOTAL PRICE TTC, TOTAL PRICE HT AND TOTAL TVA AMOUNT
export const calcTotalOrder = (order) => {
  // # GET PRODUCTS FROM ORDER
  const { items, companyDiscount, discount, deliveryCost } = order;

  // # SET ACCUMULATORS FOR OUTPUT
  let totalTtc = 0;
  let totalHt = 0;
  let totalTvaAmount = 0;
  let totalDiscountAmount = 0;
  let totalCompanyDiscountAmount = 0;
  let countProducts = 0;

  // # LOOP THROUGH ORDER'S PRODUCTS
  items.forEach((item) => {
    const { price, quantity, tvaRate } = item;
    // # TVA AS INT & SET CURR ITEM PRICE TTC
    const tvaRateNum = tvaRate.replace(',', '.') * 1;
    let currItemPriceTtc = price;

    // # CHECK IF COMPANY DISCOUNT ON ORDER
    if (companyDiscount) {
      const { value, reduction } = companyDiscount;
      if (reduction === 'percent') {
        const companyDiscountAmount = currItemPriceTtc * (value / 100);
        currItemPriceTtc -= companyDiscountAmount;

        // # INCREMENT ACCUMULATORS
        totalCompanyDiscountAmount += companyDiscountAmount * quantity;
      }
    }

    // # SET CURR ITEM PRICE HT
    const currItemPriceHt = currItemPriceTtc / (1 + tvaRateNum / 100);

    // # INCREMENT ACCUMULATORS
    totalHt += currItemPriceHt * quantity;
    totalTvaAmount += (currItemPriceTtc - currItemPriceHt) * quantity;
    totalTtc += currItemPriceTtc * quantity;
    countProducts += quantity;
  });

  // # CHECK IF DISCOUNT COUPON ON ORDER
  if (discount) {
    const { value, reduction } = discount;
    const discountAmount = reduction === 'flat' ? value : totalTtc * (value / 100);
    const discountTva = (discountAmount / (1 + 5.5 / 100)) * (5.5 / 100);

    // # INCREMENT ACCUMULATORS
    totalDiscountAmount += discountAmount;
    totalTtc -= discountAmount;
    totalHt -= discountAmount + discountTva;
    totalTvaAmount += discountTva;
  }

  // # CHECK IF DELIVERY COST
  if (deliveryCost && deliveryCost > 0) {
    const deliveryCostTva = (deliveryCost / (1 + 20 / 100)) * (20 / 100);

    // # INCREMENT ACCUMULATORS
    totalTtc += deliveryCost;
    totalTvaAmount += deliveryCostTva;
    totalHt += deliveryCost - deliveryCostTva;
  }

  // # FORMAT ACCUMULATORS FOR OUTPUT
  const formatTotalTtc = formatNum(totalTtc);
  const formatTotalTvaAmount = formatNum(totalTvaAmount);
  const formatTotalHt = formatNum(totalHt);
  const formatTotalDiscountAmount = formatNum(totalDiscountAmount);
  const formatTotalCompanyDiscountAmount = formatNum(totalCompanyDiscountAmount);

  return {
    formatTotalTtc,
    formatTotalTvaAmount,
    formatTotalHt,
    countProducts,
    formatTotalCompanyDiscountAmount,
    formatTotalDiscountAmount,
  };
};

export const totalDiscount = (orders, discount) => {
  let totalOrders = 0;
  if (discount) {
    orders.forEach((order) => {
      let totalItemsPrice;
      if (discount.reduction === 'flat') {
        totalItemsPrice = order.items.reduce(
          (acc, curr) => acc + curr.price * curr.quantity - discount.value,
          0
        );
      } else {
        totalItemsPrice = order.items.reduce(
          (acc, curr) => acc + curr.price * curr.quantity - (discount.value * curr.price) / 100,
          0
        );
      }
      const totalPrice = totalItemsPrice;
      totalOrders += totalPrice;
    });
    return currencyFormater(totalOrders);
  }
  orders.forEach((order) => {
    const totalItemsPrice = order.items.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
    const totalPrice = totalItemsPrice;
    totalOrders += totalPrice;
  });
  return currencyFormater(totalOrders);
};

export const totalPricesTable = (commande) =>
  commande.map(
    (order) =>
      Math.round(order.items.reduce((acc, curr) => acc + curr.price * curr.quantity, 0) * 100) / 100
  );

export const totalPrice = (order) => {
  if (order.discount) {
    if (order.discount.reduction === 'flat') {
      const totalItemPrice = order.items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);
      return currencyFormater(totalItemPrice + order.deliveryCost - order.discount.value);
    }
    const totalItemPrice = order.items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);
    const reduction = order.discount.value * 0.01 * totalItemPrice;
    return currencyFormater(totalItemPrice + order.deliveryCost - reduction);
  }
  const totalItemPrice = order.items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);

  return currencyFormater(totalItemPrice + order.deliveryCost);
};
